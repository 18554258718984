import { axiosAuthorized } from "../../api/config";

export const getAllJobs = async () => {
    try {
        const response = await axiosAuthorized.get(`/carrer/get_carrer`);
        return response;
    } catch (error) {
        throw error;
    }
}


export const addJob = async (initialState, key_skills, meta_tag, jobId = null) => {
    try {
        const requestData = {
            job_title: initialState.job_title,
            experience: initialState.experience,
            qualification: initialState.qualification,
            vacancy: initialState.vacancy,
            salary: initialState.salary,
            job_responsibilites: initialState.job_responsibilites,
            key_skills,
            meta_name: initialState.meta_name,
            meta_description: initialState.meta_description,
            meta_tag,
        }
        const requestConfig = {
            headers: { "Content-Type": "application/json" },
        };
        const url = jobId
            ? `/carrer/update_carrer/${jobId}`
            : `/carrer/create_carrer`;
        const response = await axiosAuthorized[jobId ? 'put' : 'post'](url, requestData, requestConfig);
        return response;
    } catch (error) {
        throw error;
    }
}

export const deleteJob = async (jobId) => {
    try {
        const response = await axiosAuthorized.delete(`/carrer/delete_carrer/${jobId}`);
        return response;
    } catch (error) {
        throw error;
    }
}