export const validateDepartmentForm = (department, department_description, department_image, department_content_image, meta_name, meta_description, meta_tag, setError) => {
  if (!department.trim().length) {
    setError({ global: "Invalid Department" });
    return false;
  }
  if (!department_description.trim().length) {
    setError({ global: "Invalid Description" });
    return false;
  }
  if (!department_image) {
    setError({ global: "Add Department Image" });
    return false;
  }
  if (!department_content_image) {
    setError({ global: "Add Department Content Image" });
    return false;
  }
  return true;
}