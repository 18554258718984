import React, { useState } from "react";
import { useForm } from "../../hooks";
import {
  AddButton,
  ImageInput,
  TextAreaInput,
  TextInput,
} from "../Common/FormComponents";
import "./AddAndEditDepartmentModal.css";
import { GrFormClose } from "react-icons/gr";
import ShowImage from "../Common/FormComponents/ShowImage/ShowImage";
import { toast } from "react-toastify";
import { addDepartment } from "../../utils/departmentUtils/departmentUtils";
import { validateDepartmentForm } from "../../utils/departmentUtils/validateDepartmentForm";
import { AiOutlineCloseCircle } from "react-icons/ai";

const AddAndEditDepartmentModal = ({
  setOpen,
  editData = null,
  setEditData,
  setDepartmentsFn,
}) => {
  const initialState = {
    department: editData?.department || "",
    department_description: editData?.department_description || "",
    meta_name: editData?.meta_name || "",
    meta_description: editData?.meta_description || "",
    slug: editData?.slug || "",
  };
  const metaTagInitialState = { tag: "" };
  const [metaFormData, handleMetaForm, clearMetaForm] =
    useForm(metaTagInitialState);
  const [metaTags, setMetaTags] = useState(editData?.meta_tag || []);
  const handleMetaTags = (action, idx = 0) => {
    if (action === "add") {
      if (!metaFormData.tag.trim().length) {
        return setError({ global: "Please Enter Tag" });
      } else {
        setMetaTags([...metaTags, metaFormData.tag]);
        clearMetaForm();
      }
    } else if (action === "remove") {
      setMetaTags(metaTags.filter((_, index) => index !== idx));
    }
  };
  const [department_content_image, setDepartmentContentImage] = useState(
    editData?.department_content_image || null
  );
  const [department_image, setDepartment_Image] = useState(
    editData?.department_image || null
  );
  const [formData, handleInput, clearForm] = useForm(initialState);
  const [err, setError] = useState({ global: "" });
  const [loading, setLoading] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      !validateDepartmentForm(
        formData.department,
        formData.department_description,
        department_image,
        department_content_image,
        formData.meta_name,
        formData.meta_description,
        metaTags,
        setError
      )
    )
      return;
    setError({ global: "" });
    setLoading(true);
    addDepartment(
      formData.department,
      formData.department_description,
      department_image,
      department_content_image,
      formData.meta_name,
      formData.meta_description,
      metaTags,
      formData?.slug,
      editData?._id
    )
      .then((res) => {
        clearForm();
        setDepartment_Image(null);
        toast(`Department ${editData ? "Edited" : "Added"}`, {
          type: "success",
          autoClose: 3000,
        });
        setEditData(null);
        setDepartmentsFn();
        setOpen(false);
      })
      .catch((err) => {
        toast(`Department ${editData ? "edit" : "add"} failed`, {
          type: "error",
          autoClose: 3000,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div className="modal-container ">
      <div className="modal">
        <div className="modal-header">
          <span>{editData ? "Edit Department" : "Add New Department"}</span>
          <span
            className="cursor-pointer"
            onClick={() => {
              clearForm();
              setOpen(false);
              setEditData(null);
            }}
          >
            <GrFormClose fontSize={16} />
          </span>
        </div>
        <div className="feild-conatainer flex-col">
          <TextInput
            data={formData}
            handleInput={handleInput}
            name={"Department"}
            formName={"department"}
            setError={setError}
          />
          <TextAreaInput
            data={formData}
            handleInput={handleInput}
            name={"Description"}
            formName={"department_description"}
            setError={setError}
          />
        </div>
        <div className="feild-conatainer">
          <ShowImage image={department_image} setImage={setDepartment_Image} />
          <ImageInput
            image={department_image}
            setImage={setDepartment_Image}
            imageName={"Department Image"}
          />
        </div>
        <div className="feild-conatainer">
          <ShowImage
            image={department_content_image}
            setImage={setDepartmentContentImage}
          />
          <ImageInput
            image={department_content_image}
            setImage={setDepartmentContentImage}
            imageName={"Department Content Image"}
          />
        </div>
        <div className="feild-conatainer flex-col">
          <TextInput
            hide={true}
            data={formData}
            handleInput={handleInput}
            name={"Slug"}
            formName={"slug"}
            setError={setError}
          />
        </div>
        <div className="feild-conatainer flex-col">
          <TextInput
            hide={true}
            data={formData}
            handleInput={handleInput}
            name={"Meta Name"}
            formName={"meta_name"}
            setError={setError}
          />
          <TextAreaInput
            hide={true}
            data={formData}
            handleInput={handleInput}
            name={"Meta Description"}
            formName={"meta_description"}
            setError={setError}
          />
        </div>
        {metaTags?.length > 0 && (
          <div className="flex w-full gap-1 flex-wrap">
            {metaTags?.map((item, idx) => (
              <div
                className="flex gap-2 items-center border  rounded-md p-1 shadow-md text-xs"
                key={idx}
              >
                <span>{item}</span>
                <button
                  onClick={() => {
                    handleMetaTags("remove", idx);
                  }}
                >
                  <AiOutlineCloseCircle fontSize={14} color="red" />
                </button>
              </div>
            ))}
          </div>
        )}
        <div className="feild-conatainer">
          <TextInput
            hide={true}
            data={metaFormData}
            handleInput={handleMetaForm}
            name={"Meta Tag"}
            formName={"tag"}
            setError={setError}
          />
          <AddButton handleAdd={handleMetaTags} />
        </div>
        {err.global && <div className="error-message">{err.global}</div>}
        <div className="feild-conatainer">
          <div className="lable-and-filed">
            <button
              className="cancle-button"
              onClick={() => {
                clearForm();
                setOpen(false);
                setEditData(null);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="lable-and-filed">
            <button
              className="submit-button"
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAndEditDepartmentModal;
