import React from 'react'
import { CallUsPoints, CountsAndDoctorImage, FaqSection, HomePageFooter, HomePageSlider, InsuranceProviders, MetaTitleDescriptionTags, OfferSlider, SymtomsList, Tags, Testominals, WhyLifePlus } from '../../components/MediaComponents';


const Media = () => {
  return (
    <div className="w-full h-full px-2 pt-10 sm:p-10 flex flex-col gap-4 relative">
      <div className="flex h-fit">
        <MetaTitleDescriptionTags />
      </div>
      <div className="grid h-fit  grid-cols-1 md:grid-cols-2 gap-10">
        <HomePageSlider />
        <OfferSlider />
      </div>
      <SymtomsList />
      <InsuranceProviders />
      {/* <div className="flex h-fit">
        <Tags />
      </div> */}
      <div className="flex h-fit">
        <WhyLifePlus />
      </div>
      <div className="flex h-fit">
        <CountsAndDoctorImage />
      </div>
      <div className="flex h-fit">
        <CallUsPoints />
      </div>
      <div className="flex h-fit">
        <Testominals />
      </div>
      <div className="flex h-fit">
        <HomePageFooter />
      </div>
      <FaqSection />
    </div>
  );
}

export default Media

