import React, { useCallback, useEffect, useState } from 'react'
import { SearchInput } from '../Common';
import { useSelector } from 'react-redux';
import LeadsTable from './LeadsTable';
import { getLeads } from '../../utils/leadsUtils/leadsUitls';
import LeadModal from './LeadModal';

const LeadsMainSection = () => {
  const user = useSelector((state) => state.userReducer.admin);
  const [leads, setLeads] = useState([]);
  const setLeadsFn = useCallback(() => {
    getLeads().then(res => setLeads(res.data.data)).catch(err=>console.log(err));
  }, [])
  useEffect(() => setLeadsFn(), [setLeadsFn]);
  const [editData, setEditData] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    const filteredData = leads?.filter((lead) => {
      const nameMatch = lead?.patient_name?.toLowerCase()
        .includes(searchQuery?.toLowerCase());
      const wNumberMatch = lead?.whats_app_number?.toString().toLowerCase()
        .includes(searchQuery?.toLowerCase());
      const cNumberMatch = lead?.contact_number?.toString()?.toLowerCase()
        .includes(searchQuery?.toLowerCase());
      const emailMatch = lead?.email_id?.toLowerCase()
        .includes(searchQuery?.toLowerCase());
      return nameMatch || wNumberMatch || cNumberMatch || emailMatch;
    });
    setFilteredData(filteredData);
  }, [leads, searchQuery]);
    useEffect(() => {
      const intervalId = setInterval(() => {
        setLeadsFn();
      }, 5 * 60 * 1000);
      return () => clearInterval(intervalId);
    }, [setLeadsFn]);
    return (
      <>
        {editData && <LeadModal setEditData={setEditData} editData={editData} setLeadsFn={setLeadsFn}/> }
        <div className=" flex w-full items-center gap-5 md:gap-0">
          <div className="flex w-fit h-fit text-xl font-semibold">Leads</div>
          <div className="flex w-full h-full justify-center items-center">
            <div className="flex w-full md:w-1/2">
              <SearchInput
                placeholder={"Search By Name, Number, Email, Disease"}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
              />
            </div>
          </div>
        </div>
        <div className="flex w-full p-3 flex-col">
          <div className="flex w-full justify-start items-center h-fit">
            <span className="font-semibold text-base">Informations</span>
          </div>
          <LeadsTable user={user} leads={filteredData} setLeadsFn={setLeadsFn} setEditData={setEditData} />
        </div>
      </>
    );
}

export default LeadsMainSection