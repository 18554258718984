import React, { useEffect, useRef, useState } from "react";
import { GrFormClose } from "react-icons/gr";
import { AddButton, ImageInput, SelectInput, ShowImage, TextInput } from "../Common/FormComponents";
import { vaidateTreatmentForm } from "../../utils/treamentUtils/validateTreatmentForm";
import { useForm } from "../../hooks";
import { addTreatment } from "../../utils/treamentUtils/treatmentUtils";
import PackageSection from "./PackageSection";
import ContentSection from "./ContentSection";
import FaqSection from "./FaqSection";
import { getDepartments } from "../../utils/departmentUtils/departmentUtils";
import { AiOutlineCloseCircle } from "react-icons/ai";
import JoditEditor, {Jodit} from "jodit-react";
import { toast } from "react-toastify";

const config = {
  readonly: false,
  placeholder: "Description",
  uploader: {
    insertImageAsBase64URI: true,
  },
  controls: {
    paragraph: {
      list: Jodit.atom({
        p: "Pharagraph",
        h1: "Heading 1",
        h2: "Heading 2",
        h3: "Heading 3",
        h4: "Heading 4",
        h5: "Heading 5",
        h6: "Heading 6",
        blockquote: "Quote",
        div: "Div",
        pre: "Source code",
      }),
    },
  },
};
const TreatmentModal = ({
  setOpen,
  editData = null,
  setEditData,
  setTreatmentFn,
}) => {
  const treatmentInitialState = {
    name: editData?.sub_department_name || "",
    department: editData?.department_id?.department || "",
    meta_name: editData?.meta_name || "",
    meta_description: editData?.meta_description || "",
    slug: editData?.slug || "",
  };
  const [treatment_image, setTreatmentImage] = useState(
    editData?.treatment_image || null
  );
  const editor = useRef(null);
  const [description, setDescription] = useState(
    editData?.sub_department_descriptiion || "<p></p>"
  );
  const metaTagInitialState = { tag: "" };
  const [metaFormData, handleMetaForm, clearMetaForm] =
    useForm(metaTagInitialState);
  const [metaTags, setMetaTags] = useState(editData?.meta_tag || []);
  const handleMetaTags = (action, idx = 0) => {
    if (action === "add") {
      if (!metaFormData.tag.trim().length) {
        return setErr({ global: "Please Enter Tag" });
      } else {
        setMetaTags([...metaTags, metaFormData.tag]);
        clearMetaForm();
      }
    } else if (action === "remove") {
      setMetaTags(metaTags.filter((_, index) => index !== idx));
    }
  };
  const symptomInitialState = { symptom: "" };
  const [symptomFormData, handleSymptomForm, clearSymptomForm] =
    useForm(symptomInitialState);
  const [symptoms, setSymptoms] = useState(editData?.symptoms || []);
  const handleSymptoms = (action, idx = 0) => {
    if (action === "add") {
      if (!symptomFormData.symptom.trim().length) {
        return setErr({ symptom: "Please Enter Symptom" });
      } else {
        setSymptoms([...symptoms, symptomFormData.symptom]);
        clearSymptomForm();
      }
    } else if (action === "remove") {
      setSymptoms(symptoms.filter((_, index) => index !== idx));
    }
  };
  const [treatmentData, handleTreatmentData, clearForm] = useForm(
    treatmentInitialState
  );
  const [departments, setDepartments] = useState([]);
  const [err, setErr] = useState({ global: "", symptom: "" });
  const [deps, setDeps] = useState([]);
  const [dpId, setDpId] = useState(0);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getDepartments()
      .then((res) => {
        setDepartments([
          { department: "Select Department", _id: 0 },
          ...res.data.data,
        ]);
        setDeps([
          "Select Department",
          ...res.data.data.map((item) => item.department),
        ]);
      })
      .catch((err) => {
        setDepartments([{ department: "Select Department", _id: 0 }]);
      });
  }, []);
  useEffect(() => {
    const foundDepartment = departments.find(
      (dep) => dep.department === treatmentData.department
    );
    if (foundDepartment) {
      setDpId(foundDepartment._id);
    }
  }, [departments, treatmentData.department]);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      vaidateTreatmentForm(treatmentData, description, dpId,symptoms, metaTags,treatment_image, setErr)
    ) {
      setErr({ global: "" });
      setLoading(true);
      addTreatment(
        treatmentData,
        description,
        dpId,
        symptoms,
        metaTags,
        treatment_image,
        editData?._id
      )
        .then((res) => {
          setErr({ global: "" });
          toast(`Treatment ${editData ? "Edited" : "Added"}`, {
            type: "success",
            autoClose: 3000,
          });
          setTreatmentImage(null);
          setEditData(null);
          setOpen(false);
          setTreatmentFn();
        })
        .catch((err) => {
          toast(`Treatment ${editData ? "Edited Failed" : "Added Failed"}`, {
            type: "error",
            autoClose: 3000,
          });
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  return (
    <div className="absolute flex w-full h-full overflow-y-auto top-0 left-0 bottom-0 justify-center items-center p-4 ">
      <div
        className={`flex bg-white w-full max-w-screen-md overflow-y-auto  border shadow-lg  rounded-md gap-5 px-4 pb-4 pt-2 flex-col h-full`}
      >
        <div className="flex w-full h-fit items-center justify-between font-semibold">
          <span>{editData ? "Edit Treatment" : "Add Treatment"}</span>
          <span
            className="cursor-pointer"
            onClick={() => {
              setEditData(null);
              clearForm();
              setEditData(null);
              setOpen(false);
            }}
          >
            <GrFormClose fontSize={16} />
          </span>
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <TextInput
            name={"Name"}
            formName={"name"}
            data={treatmentData}
            handleInput={handleTreatmentData}
            setError={setErr}
          />
          <SelectInput
            name={"Department"}
            formName={"department"}
            data={treatmentData}
            handleInput={handleTreatmentData}
            items={deps}
            setError={setErr}
          />
        </div>
        <div className="flex flex-col w-full gap-0.5">
          <label htmlFor={"Description"} className="label">
            {"Description"} <span className="text-red-600">*</span>
          </label>
          <JoditEditor
            ref={editor}
            value={description}
            config={config}
            tabIndex={1}
            onBlur={(newContent) =>
              setDescription(
                newContent.replace(
                  /<td(?:[^>]*)style="[^"]*"(?:[^>]*)>/g,
                  '<td style="border: 1px solid black;" >'
                )
              )
            }
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <ShowImage image={treatment_image} setImage={setTreatmentImage} />
          <ImageInput
            imageName={"Treatment Image"}
            setImage={setTreatmentImage}
          />
        </div>
        {editData && (
          <>
            <PackageSection selectedTreatment={editData} />
            <ContentSection selectedTreatment={editData} />
            <FaqSection selectedTreatment={editData} />
          </>
        )}
        {symptoms?.length > 0 && (
          <div className="flex w-full gap-1 flex-wrap">
            {symptoms?.map((item, idx) => (
              <div
                className="flex gap-2 items-center border  rounded-md p-1 shadow-md text-xs"
                key={idx}
              >
                <span>{item}</span>
                <button
                  onClick={() => {
                    handleSymptoms("remove", idx);
                  }}
                >
                  <AiOutlineCloseCircle fontSize={14} color="red" />
                </button>
              </div>
            ))}
          </div>
        )}
        <div className="feild-conatainer">
          <TextInput
            data={symptomFormData}
            handleInput={handleSymptomForm}
            name={"Symptom"}
            formName={"symptom"}
            setError={setErr}
          />
          <AddButton handleAdd={handleSymptoms} />
        </div>
        {err.symptom && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.symptom}
          </div>
        )}
        <div className="feild-conatainer flex-col">
          <TextInput
            hide={true}
            data={treatmentData}
            handleInput={handleTreatmentData}
            name={"Slug"}
            formName={"slug"}
            setError={setErr}
          />
        </div>
        <div className="feild-conatainer flex-col">
          <TextInput
            data={treatmentData}
            handleInput={handleTreatmentData}
            name={"Meta Name"}
            formName={"meta_name"}
            setError={setErr}
          />
          <TextInput
            data={treatmentData}
            handleInput={handleTreatmentData}
            name={"Meta Description"}
            formName={"meta_description"}
            setError={setErr}
          />
        </div>
        {metaTags?.length > 0 && (
          <div className="flex w-full gap-1 flex-wrap">
            {metaTags?.map((item, idx) => (
              <div
                className="flex gap-2 items-center border  rounded-md p-1 shadow-md text-xs"
                key={idx}
              >
                <span>{item}</span>
                <button
                  onClick={() => {
                    handleMetaTags("remove", idx);
                  }}
                >
                  <AiOutlineCloseCircle fontSize={14} color="red" />
                </button>
              </div>
            ))}
          </div>
        )}
        <div className="feild-conatainer">
          <TextInput
            data={metaFormData}
            handleInput={handleMetaForm}
            name={"Meta Tag"}
            formName={"tag"}
            setError={setErr}
          />
          <AddButton handleAdd={handleMetaTags} />
        </div>
        {err.global && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.global}
          </div>
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <div className="flex flex-col w-full gap-0.5">
            <button
              className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
              onClick={() => {
                clearForm();
                setOpen(false);
                setEditData(null);
                setOpen(false);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="flex flex-col w-full gap-0.5">
            <button
              className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TreatmentModal;
