import { axiosAuthorized } from "../../api/config";

export const getTreatments = async () => {
  try {
    const response = await axiosAuthorized.get("/getsubdepartment");
    return response;
  } catch (error) {
    throw error;
  }
}

export const addTreatment = async (initialState, sub_department_descriptiion, dpId, symptoms, meta_tag, treatment_image, subdepartment_id = null) => {
  try {
    const requestData = {
      sub_department_name: initialState.name,
      slug: initialState.slug,
      department_id: dpId,
      sub_department_descriptiion,
      meta_name: initialState.meta_name,
      treatment_image,
      meta_description: initialState.meta_description,
      meta_tag,
      symptoms
    };

    const requestConfig = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    const url = subdepartment_id
      ? `/department/update_subdepartment/${subdepartment_id}`
      : `/department/add_subdepartment`;

    const response = await axiosAuthorized[subdepartment_id ? 'put' : 'post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteTreatment = async (treatmentId) => {
  try {
    const response = await axiosAuthorized.delete(`/department/delete_subdepartment/${treatmentId}`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const addPackage = async (initialState, benefits, treatmentId, image) => {
  try {
    const response = await axiosAuthorized.post('/package/add_package', {
      subdepartment_id: treatmentId,
      package_name: initialState.name,
      price: initialState.price,
      benefits,
      duration: initialState.duration,
      image,
    },
      {
        headers: { "Content-Type": "multipart/form-data" }
      });
    return response;
  } catch (error) {
    throw error;
  }
};

export const deletePackage = async (packageId) => {
  try {
    const response = await axiosAuthorized.delete(`/package/delete_package/${packageId}`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const getPackages = async (treatmentId) => {
  try {
    const response = await axiosAuthorized.get(`/packages/${treatmentId}`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const addContent = async (question, answer, treatmentId, image, contentId = null) => {
  try {
    const data = {
      subdepartment_id: treatmentId,
      question,
      answer,
    }
    const requestData = image ? { ...data, content_images: image } : { ...data };
    const url = contentId ? `/content/update_content/${contentId}` : `/content/add_content`
    const response = await axiosAuthorized[contentId ? 'put' : 'post'](url, requestData,
      {
        headers: { "Content-Type": "multipart/form-data" }
      });
    return response;
  } catch (error) {
    throw error;
  }
}

export const getContents = async (treatmentId) => {
  try {
    const response = await axiosAuthorized.get(`/content/${treatmentId}`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteContenet = async (contentId) => {
  try {
    const response = await axiosAuthorized.delete(`/content/delete_content/${contentId}`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const addFaq = async (initialState, treatmentId) => {
  try {
    const response = await axiosAuthorized.post('/faq/add_faq', {
      subdepartment_id: treatmentId,
      faq_title: initialState.title,
      faq_answer: initialState.answer,
    },
      {
        headers: { "Content-Type": "application/json" }
      });
    return response;
  } catch (error) {
    throw error;
  }
}


export const getFaqs = async (treatmentId) => {
  try {
    const response = await axiosAuthorized.get(`/faq/${treatmentId}`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const deleteFaq = async (faqId) => {
  try {
    const response = await axiosAuthorized.delete(`/faq/delete_faq/${faqId}`);
    return response;
  } catch (error) {
    throw error;
  }
}