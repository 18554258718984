import React, { useEffect, useState } from "react";
import { useForm } from "../../hooks";
import { getDepartments } from "../../utils/departmentUtils/departmentUtils";
import "./AddAndEditModal.css";
import {
  AddButton,
  ImageInput,
  MapDegree,
  MapSpecialities,
  NumberInput,
  SelectInput,
  TextAreaInput,
  TextInput,
} from "../Common/FormComponents";
import ShowImage from "../Common/FormComponents/ShowImage/ShowImage";
import { toast } from "react-toastify";
import { GrFormClose } from "react-icons/gr";
import { validateDoctorForm } from "../../utils/doctorUtils/validateDoctorForm";
import { addDoctor } from "../../utils/doctorUtils/doctorUtils";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { getTreatments } from "../../utils/treamentUtils/treatmentUtils";

const AddAndEditModal = ({
  setOpen,
  editData = null,
  setEditData,
  setDoctorsFn,
}) => {
  const [departments, setDepartments] = useState([]);
  const [treatments, setTreatments] = useState([]);
  const initialState = {
    name: editData?.name || "",
    department: editData?.department_id?.department || "Select Department",
    subdepartment_id:
      editData?.subdepartment_id?.sub_department_name || "Select Treatment",
    email: editData?.email || "",
    phone: editData?.phone_number || "",
    experience: editData?.experience || "",
    gender: editData?.gender || "Select Gender",
    age: editData?.age || "",
    meta_name: editData?.meta_name || "",
    meta_description: editData?.meta_description || "",
  };
  const metaTagInitialState = { tag: "" };
  const [metaFormData, handleMetaForm, clearMetaForm] =
    useForm(metaTagInitialState);
  const [metaTags, setMetaTags] = useState(editData?.meta_tag || []);
  const handleMetaTags = (action, idx = 0) => {
    if (action === "add") {
      if (!metaFormData.tag.trim().length) {
        return setError({ global: "Please Enter Tag" });
      } else {
        setMetaTags([...metaTags, metaFormData.tag]);
        clearMetaForm();
      }
    } else if (action === "remove") {
      setMetaTags(metaTags.filter((_, index) => index !== idx));
    }
  };
  const [formData, handleInput, clearForm] = useForm(initialState);
  const [deps, setDeps] = useState([]);
  const [dpId, setDpId] = useState(0);
  useEffect(() => {
    getDepartments()
      .then((res) => {
        setDepartments([
          { department: "Select Department", _id: 0 },
          ...res.data.data,
        ]);
        setDeps([
          "Select Department",
          ...res.data.data.map((item) => item.department),
        ]);
      })
      .catch((err) => {
        setDepartments([{ department: "Select Department", _id: 0 }]);
      });
  }, []);
  useEffect(() => {
    const foundDepartment = departments.find(
      (dep) => dep.department === formData.department
    );
    if (foundDepartment) {
      setDpId(foundDepartment._id);
    }
  }, [departments, formData.department]);
  const [treats, setTreats] = useState([]);
  const [treatId, setTreatId] = useState(0);
  useEffect(() => {
    getTreatments()
      .then((res) => {
        setTreatments([
          { sub_department_name: "Select Treatment", _id: 0 },
          ...res.data.data,
        ]);
        setTreats([
          "Select Treatment",
          ...res.data.data.map((item) => item.sub_department_name),
        ]);
      })
      .catch((err) => {
        setTreatments([{ sub_department_name: "Select Treatment", _id: 0 }]);
      });
  }, []);
  useEffect(() => {
    const foundTreatment = treatments.find(
      (dep) => dep.sub_department_name === formData.subdepartment_id
    );
    if (foundTreatment) {
      setTreatId(foundTreatment._id);
    }
  }, [treatments, formData.subdepartment_id]);
  const [loading, setLoading] = useState(false);
  const degreeInitialState = { degree: "", year: 2023 };
  const [degreeData, setDegreeData] = useState(editData?.qualification || []);
  const years = Array.from(
    { length: new Date().getFullYear() + 1 - 1970 },
    (_, i) => 1970 + i
  ).reverse();
  const [err, setError] = useState({ global: "", speciality: "", degree: "" });
  const [degreeFormData, handleDegreeInput, clearDegreeForm] =
    useForm(degreeInitialState);
  const handleDegree = (action, idx = 0) => {
    if (action === "add") {
      if (!degreeFormData.degree.trim().length) {
        return setError({ degree: "Please enter a Degree" });
      } else {
        setDegreeData([
          ...degreeData,
          { degree: degreeFormData.degree, year: degreeFormData.year },
        ]);
        clearDegreeForm();
      }
    } else if (action === "remove") {
      setDegreeData(degreeData.filter((_, index) => index !== idx));
    }
  };
  const [specialities, setSpecialites] = useState(editData?.speciality || []);
  const [speciality, handleSpecialityInput, clearSpecialitesFeild] = useForm({
    speciality: "",
  });
  const handleSpecialities = (action, idx = 0) => {
    if (action === "add") {
      if (!speciality.speciality.trim().length) {
        return setError({ speciality: "Please Enter Specialities" });
      } else {
        setSpecialites([...specialities, speciality.speciality]);
        clearSpecialitesFeild();
      }
    } else if (action === "remove") {
      setSpecialites(specialities.filter((_, index) => index !== idx));
    }
  };
  const [profileImage, setProfileImage] = useState(
    editData?.doctor_image || null
  );
  const [coverImage, setCoverImage] = useState(
    editData?.doctor_cover_image || null
  );
  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      !validateDoctorForm(
        formData,
        dpId,
        treatId,
        degreeData,
        specialities,
        profileImage,
        coverImage,
        metaTags,
        setError
      )
    )
      return;
    setError({ global: "" });
    setLoading(true);
    addDoctor(
      formData,
      dpId,
      treatId,
      degreeData,
      specialities,
      profileImage,
      coverImage,
      metaTags,
      editData?._id
    )
      .then((res) => {
        setError({ degree: "", global: "", speciality: "" });
        setDoctorsFn();
        clearForm();
        setCoverImage(null);
        setProfileImage(null);
        toast(`Doctor ${editData ? "Edited" : "Added"}`, {
          type: "success",
          autoClose: 3000,
        });
        setEditData(null);
        setOpen(false);
      })
      .catch((err) => {
        console.log(err);
        toast(`Doctor ${editData ? "edit" : "add"} failed`, {
          type: "error",
          autoClose: 3000,
        });
      })
      .finally(() => setLoading(false));
  };
  return (
    <div className="modal-container">
      <div className="modal">
        <div className="modal-header">
          <span>{editData ? "Edit Doctor" : "Add New Doctor"}</span>
          <span
            className="cursor-pointer"
            onClick={() => {
              clearForm();
              setOpen(false);
              setEditData(null);
            }}
          >
            <GrFormClose fontSize={16} />
          </span>
        </div>
        <div className="feild-conatainer">
          <TextInput
            data={formData}
            handleInput={handleInput}
            name={"Name"}
            formName={"name"}
            setError={setError}
          />
          <SelectInput
            data={formData}
            handleInput={handleInput}
            items={["Select Gender", "Male", "Female"]}
            name={"Gender"}
            formName={"gender"}
            setError={setError}
          />
          <NumberInput
            hide={true}
            data={formData}
            handleInput={handleInput}
            name={"Age"}
            formName={"age"}
            setError={setError}
            min={18}
          />
        </div>
        <div className="feild-conatainer">
          <TextInput
            data={formData}
            handleInput={handleInput}
            name={"Email"}
            formName={"email"}
            setError={setError}
          />
          <TextInput
            data={formData}
            handleInput={handleInput}
            name={"Phone"}
            formName={"phone"}
            setError={setError}
          />
          <NumberInput
            data={formData}
            hide={true}
            handleInput={handleInput}
            name={"Experience"}
            formName={"experience"}
            setError={setError}
          />
        </div>
        <div className="feild-conatainer">
          <SelectInput
            data={formData}
            handleInput={handleInput}
            items={deps}
            name={"Department"}
            formName={"department"}
            setError={setError}
          />
          <SelectInput
            data={formData}
            handleInput={handleInput}
            items={treats}
            name={"Treatment"}
            formName={"subdepartment_id"}
            setError={setError}
          />
        </div>
        {degreeData.length > 0 && (
          <MapDegree data={degreeData} handleData={handleDegree} />
        )}
        <div className="feild-conatainer">
          <TextInput
            data={degreeFormData}
            handleInput={handleDegreeInput}
            name={"Degree"}
            formName={"degree"}
            setError={setError}
          />
          <SelectInput
            data={degreeFormData}
            handleInput={handleDegreeInput}
            items={years}
            name={"Year"}
            formName={"year"}
            setError={setError}
          />
          <AddButton handleAdd={handleDegree} />
        </div>
        {err.degree && <div className="error-message">{err.degree}</div>}
        {specialities.length > 0 && (
          <MapSpecialities
            data={specialities}
            handleData={handleSpecialities}
          />
        )}
        <div className="feild-conatainer">
          <TextInput
            data={speciality}
            handleInput={handleSpecialityInput}
            name={"Speciality"}
            formName={"speciality"}
            setError={setError}
          />
          <AddButton handleAdd={handleSpecialities} />
        </div>
        {err.speciality && (
          <div className="error-message">{err.speciality}</div>
        )}
        <div className="feild-conatainer">
          <ImageInput
            image={profileImage}
            setImage={setProfileImage}
            imageName={"Profile Image"}
          />
          <ImageInput
            image={coverImage}
            setImage={setCoverImage}
            imageName={"Cover Image"}
          />
        </div>
        <div className="feild-conatainer">
          {
            <>
              <ShowImage image={profileImage} setImage={setProfileImage} />
              <ShowImage image={coverImage} setImage={setCoverImage} />
            </>
          }
        </div>
        <div className="feild-conatainer flex-col">
          <TextInput
            hide={true}
            data={formData}
            handleInput={handleInput}
            name={"Meta Name"}
            formName={"meta_name"}
            setError={setError}
          />
          <TextAreaInput
            hide={true}
            data={formData}
            handleInput={handleInput}
            name={"Meta Description"}
            formName={"meta_description"}
            setError={setError}
          />
        </div>
        {metaTags?.length > 0 && (
          <div className="flex w-full gap-1 flex-wrap">
            {metaTags?.map((item, idx) => (
              <div
                className="flex gap-2 items-center border  rounded-md p-1 shadow-md text-xs"
                key={idx}
              >
                <span>{item}</span>
                <button
                  onClick={() => {
                    handleMetaTags("remove", idx);
                  }}
                >
                  <AiOutlineCloseCircle fontSize={14} color="red" />
                </button>
              </div>
            ))}
          </div>
        )}
        <div className="feild-conatainer">
          <TextInput
            hide={true}
            data={metaFormData}
            handleInput={handleMetaForm}
            name={"Meta Tag"}
            formName={"tag"}
            setError={setError}
          />
          <AddButton handleAdd={handleMetaTags} />
        </div>
        {err.global && <div className="error-message">{err.global}</div>}
        <div className="feild-conatainer">
          <div className="lable-and-filed">
            <button
              className="cancle-button"
              onClick={() => {
                clearForm();
                setOpen(false);
                setEditData(null);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="lable-and-filed">
            <button
              className="submit-button"
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAndEditModal;
