import { axiosAuthorized } from "../../api/config";

export const getOfferImages = async () => {
    try {
        const response = await axiosAuthorized.get("/offerslider/get_offerslider");
        return response;
    } catch (error) {
        throw error;
    }
}

export const addOfferImage = async (initialState, image) => {
    try {
        const response = await axiosAuthorized.post("/offerslider/add_offerslider", {
            offer_type: initialState.category,
            offer_slider: image
        }, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        return response;
    } catch (error) {
        throw error;
    }
}

export const deleteOfferImage = async (imageId) => {
    try {
        const response = await axiosAuthorized.delete(`/offerslider/delete_offerslider/${imageId}`);
        return response;
    } catch (error) {
        throw error;
    }
}